@use '@angular/material' as mat;
@use '@dhutaryan/ngx-mat-timepicker' as mat-timepicker;
@use '@ng-matero/extensions' as mtx;
@use 'sass:map';
@use 'app-palettes' as p;

@function define-light-theme($palette) {
  @return mat.m2-define-light-theme(
    (
      color: (
        primary: mat.m2-define-palette($palette),
        accent: mat.m2-define-palette(p.$web-palette, A200, A100, A400)
      ),
      typography: mat.m2-define-typography-config(),
      density: -3
    )
  );
}

// Styles for the docs app that are based on the current theme.
@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.m2-define-palette(mat.$m2-red-palette);
  $primary-color: mat.m2-get-color-from-palette($primary);
  $foreground-text: mat.m2-get-color-from-palette($foreground, text);

  @include mat.button-density(0);
  @include mat.checkbox-density(0);
  @include mat.tabs-density(0);
  @include mat.expansion-density(-1);
  @include mat-timepicker.timepicker-theme($theme);
  @include mtx.all-component-themes($theme);

  .compact-form-field {
    @include mat.form-field-density(-5);
  }

  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border-color: $primary-color !important;
  }

  .table-wrapper {
    .interaction {
      .filter-background {
        background: mat.m2-get-color-from-palette($primary, 0.1);
      }
    }

    .table {
      .ag-cell-value,
      .ag-row-group,
      .ag-checkbox-input-wrapper.ag-checked::after {
        color: $primary-color;
      }
    }
  }

  .primary-color,
  .router-link,
  .sidenav-content .action,
  .mat-expansion-indicator:after {
    color: $primary-color;
  }

  button mat-icon[color='primary-opposite'] {
    // opposite of default contrast color
    color: mat.m2-get-color-from-palette($background, card);
  }

  .mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
    color: $primary-color;
  }

  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $primary-color;
  }

  .mat-accordion {
    .mat-expansion-panel-body {
      .subnav {
        &:hover,
        &.active {
          color: $foreground-text;
        }
      }
    }
  }

  .status-step {
    &.primary {
      background-color: $primary-color !important;
    }
  }

  mat-accordion {
    &.settings-accordion {
      mat-panel-title {
        color: $primary-color;
      }
    }
  }

  .builder-fields {
    .isi-field,
    .draggable-copy-inner {
      background-color: $primary-color;
    }
  }

  // AAK iStudy styles start
  .widget-wrapper {
    .widget-header {
      background-color: $primary-color;
    }
  }

  .details-wrapper {
    mat-accordion {
      mat-panel-title {
        color: $primary-color;
      }
    }
  }
  // AAK iStudy styles end
}

.mat-elevation-z0 {
  @include mat.elevation(0)
}

.mat-elevation-z1 {
  @include mat.elevation(1)
}

.mat-elevation-z3 {
  @include mat.elevation(3)
}
