@use "settings" as *

/********** CUSTOM START **********/
$grid-column-decrease: calc(#{$grid-gap}/2)

.grid-two-cols
  @include grid-two-cols

  &.width-based
    grid-template-columns: calc(50% - $grid-column-decrease) calc(50% - $grid-column-decrease) [end]

.label-custom
  font-size: 12px
  color: $gray-label
  letter-spacing: normal
  line-height: normal

.info
  &.disabled
    color: $gray-normal

  &.mb-10
    margin-bottom: 10px

  &.mb-20
    margin-bottom: 20px

.logo
  &.invert
    @include inverted-logo

.no-data
  color: $gray-normal
  margin: $indent
  text-align: center
  font-size: 14px

#focused-name
  font-weight: 600

.custom-error
  @include custom-error

  &.editor
    margin: 0 0 0 $indent

  &.select
    margin: 0 0 5px $indent

  &.mb-0
    margin-bottom: 0

.error
  color: $error

.label
  font-size: 12px
  color: $gray-label

.between
  display: flex
  justify-content: space-between
  align-items: center

  > a, > div > .button, > div > .info, mat-spinner, mat-slide-toggle
    margin-left: 10px

    &.mb-20
      margin-bottom: 20px

  mat-form-field, scam-chips-autocomplete, scam-copy-text-field, app-roles-users-select, app-autocomplete, scam-date-picker
    width: 95%

.header-icon // in use for getAttachmentColDef
  display: block!important
  opacity: .6

.router-link
  text-decoration: none
  cursor: pointer

/********** CUSTOM END **********/

/********** LIST, SETTINGS START **********/

#router-content
  height: calc(100% - #{$router-paddings})
  padding: $indent

  &.table-content
    height: $tabs-router-paddings

  &.child-table
    height: $tabs-router-paddings

    scam-table
      .table-wrapper
        height: calc(100% - #{$header-height + $router-paddings})

  &.accordion-content
    height: auto
    min-height: $tabs-router-paddings

    &.footer
      min-height: $tabs-footer-router-paddings

@media (max-width: $breakpoint-mobile)
  #router-content
    height: $content-height
    padding: 0

    &.table-content
      height: $toolbar-tabs

    &.child-table
      height: $toolbar-tabs
      padding: 0

      scam-table
        .table-wrapper
          height: calc(100% - #{$header-height-mobile + $indent})

    &.accordion-content
      min-height: $toolbar-tabs
      padding-bottom: $toolbar-height

      &.footer
        min-height: $height-with-tabs-mobile
        padding-bottom: $double-toolbar

/********** LIST, SETTINGS END **********/

/********** DETAILS PAGE START **********/

.content-wrapper
  padding: $indent
  min-height: $details-height

  &.tabs
    min-height: $tabs-footer-router-paddings

  &.settings
    padding: $indent*3
    min-height: $tabs-footer-router-paddings

  .header // TODO remove correspondenceDetails
    .email-buttons
      width: 100%
      @include jce

      mat-icon
        &:not(:last-child)
          margin-right: $indent

        &.disabled
          pointer-events: none

  .entity-details
    background: $white
    padding: $indent*3
    transition: .5s

    form
      &:not(.form-flex-rows) /* isi-form exception */
        @include grid-two-cols

        .col-left
          @include grid-two-cols

          .col-100
            @include col-100
            width: 100%

        .col-right
          @include grid-two-cols

          .col-100
            @include col-100
            width: 100%

        .email
          &:not(.removable)
            mat-form-field
              width: 100%

          &.removable
            display: grid
            grid-template-columns: 8fr 1fr
            grid-gap: $indent

          mat-icon
            cursor: pointer
            align-self: center
            margin: 0 0 0 auto

@media (max-width: $breakpoint-tablet)
  .content-wrapper
    .entity-details
      padding: $indent $indent*2 $indent*2

      form
        @include d-block
        grid-template-columns: none

@media (max-width: $breakpoint-mobile)
  .content-wrapper
    padding: 0 0 $double-toolbar
    min-height: calc(100% - #{$double-toolbar})

    &.tabs
      min-height: $height-with-tabs-mobile

    ::ng-deep.go-to-button
      display: none

    .entity-details
      border-top: $border
      border-bottom: $border
      box-shadow: none
      margin-bottom: 0
      padding: $indent

/********** DETAILS PAGE END **********/

/********** ACCORDION START **********/

.settings-accordion
  .mat-expansion-panel
    &.mat-expanded
      overflow: visible // ck-editor panels visibility fix

    &.mat-expansion-panel-spacing
      margin: $indent 0

    &.content-builder
      overflow: visible

      .mat-expansion-panel-body
        max-width: 100vw
        padding: 0

    &.table
      .mat-expansion-panel-body
        padding: 0

        .table-wrapper
          height: 50vh

    &.w-100
      .grid-two-cols
        @include grid-two-cols

    .mat-expansion-panel-header
      height: auto
      min-height: 44px
      transition: .1s

      &.mat-expanded
        min-height: 60px

      .mat-expansion-panel-header-description
        @include jce

      &.initial
        flex-grow: initial

    &:not(.table):not(.w-100)
      .mat-expansion-panel-body
        @include grid-two-cols

        .col-left, .col-right
          @include grid-two-cols

        .col-100
          @include col-100

          &.centered
            @include jcc

        .lifespan
          grid-template-columns: 3fr 1fr

        .book
          grid-template-columns: 4fr 1fr 1fr

        // TODO figure out usage and scope it
        //span
        //  display: inline-block
        //  margin-right: 5px

    mat-form-field
      width: 100%

    mat-panel-title
      font-size: 16px
      font-weight: 600
      align-self: center

      &.disabled
        color: $gray-label

    .between > mat-form-field
      width: 95%

@media (max-width: $breakpoint-tablet)
  .settings-accordion
    .mat-expansion-panel
      &:not(.table):not(.w-100)
        .mat-expansion-panel-body
          grid-template-columns: 1fr [end]

@media (max-width: $breakpoint-mobile)
  .settings-accordion
    .mat-expansion-panel
      &:not(.table):not(.w-100)
        .mat-expansion-panel-body
          .col-left, .col-right
            grid-template-columns: 1fr

            .hidden-mobile // TODO replace with .one-col
              display: none

            mat-form-field, scam-date-picker, app-project-picker, app-slide-toggle, .between
              @include col-100

          .accordion-item-mobile
            &:not(:last-of-type)
              @include accordion-item

@media (max-width: 400px)
  .settings-accordion
    .mat-expansion-panel
      &:not(.table):not(.w-100)
        .mat-expansion-panel-body
          .grid-two-cols, .grid-two-cols.width-based
            grid-template-columns: 100% [end]
            margin-bottom: $indent*2

          .accordion-item-small
            &:not(:last-of-type)
              @include accordion-item

/********** ACCORDION END **********/

/********** DIALOG START **********/

.resizeable-dialog
  .mat-mdc-dialog-container
    min-width: 300px
    min-height: 200px
    resize: both
    overflow: auto

.mat-mdc-dialog-content
  padding-bottom: 0!important

  .content
    padding-top: 5px

    > p:first-child
      margin-top: 0

    &.ff-column
      flex-flow: column

    mat-form-field
      width: 100%

    .entity-details
      form
        grid-template-columns: 1fr [end]

    .toggle
      text-align: center
      padding-top: 5px

      mat-slide-toggle
        margin-right: $indent

    .w-100
      width: 100%

      mat-form-field
        width: 100%

    .grid-three-cols
      @include grid-three-cols

    .image-container
      width: $profile-image-size
      height: $profile-image-size

      img
        @include fit-image

    .settings
      mat-divider
        margin-bottom: $indent

.mat-mdc-dialog-actions
  justify-content: flex-end!important
  padding: $indent 24px $indent!important

@media (max-width: $breakpoint-mobile)
  .mat-mdc-dialog-content
    .content
      .entity-details
        form
          .col-left, .col-right
            @include d-block

    .organization
      mat-form-field
        min-width: 100%

/********** DIALOG END **********/
