// colors
$gray-light: rgba(0, 0, 0, 0.12)
$gray-normal: rgba(0, 0, 0, 0.38)
$gray-label: rgba(0, 0, 0, 0.6)!important
$gray-disabled: rgba(0, 0, 0, 0.87)!important
$gray: #757575
$gray-ck: #c4c4c4
$gray-darker: #676767
$gray-router: #fafafa
$white: #fff
$green: #1d8809
$red: #cf091e
$blue: #3f51b5
$nav-dark: #343539
$error: #f44336

// borders
$border: 1px solid $gray-light
$border-ck: 1px solid $gray-ck
$border-dashed: 1px dashed #9e9e9e

// sizes
$toolbar-borders: 2px
$indent: 16px
$grid-gap: 22px
$breakpoint-mobile: 800px
$breakpoint-tablet: 1200px
$user-img-size: 40px
$sidenav-width: 250px
$profile-image-size: 250px
$toolbar-height: 64px
$nav-tabs-height: 48px
$tabs-height: $nav-tabs-height + 1px // + border-bottom
$router-paddings: $indent*2 // padding-top + padding-bottom
$footer-height: $toolbar-height + $router-paddings + $toolbar-borders
$tabs-footer-height: $tabs-height + $footer-height
$header-height: 92px
$header-height-mobile: 62px

// custom heights mobile
$double-toolbar: $toolbar-height*2 + $toolbar-borders

// custom calculated heights desktop
$content-height: calc(100% - #{$toolbar-height})
$toolbar-tabs: calc(100% - #{$toolbar-height + $tabs-height})
$tabs-router-paddings: calc(100% - #{$tabs-height + $router-paddings})
$tabs-header-router-paddings: calc(100% - #{$tabs-height + $header-height + $router-paddings})
$tabs-footer-router-paddings: calc(100% - #{$tabs-footer-height + $router-paddings})
$details-height: calc(100% - #{$footer-height + $router-paddings})
$height-with-tabs-mobile: calc(100% - #{$double-toolbar + $tabs-height})

@mixin mover
  0%
    transform: translateY(-5px)
  100%
    transform: translateY(0)

@-webkit-keyframes mover
  @include mover

@keyframes mover
  @include mover

@mixin d-flex
  display: flex

@mixin jcc
  @include d-flex
  justify-content: center

@mixin jce
  @include d-flex
  justify-content: flex-end

@mixin jcs
  @include d-flex
  justify-content: flex-start

@mixin jcb
  @include d-flex
  justify-content: space-between

@mixin aic
  @include d-flex
  align-items: center

@mixin d-block
  display: block

@mixin d-grid
  display: grid

@mixin col-100
  grid-column: 1/end

@mixin grid-two-cols
  display: grid
  grid-template-columns: 1fr 1fr [end]
  grid-gap: 5px $grid-gap
  grid-auto-rows: min-content

@mixin grid-three-cols
  @include grid-two-cols
  grid-template-columns: 1fr 1fr 1fr [end]

@mixin d-none
  display: none

@mixin p-absolute
  position: absolute

@mixin between-align-center
  @include aic
  @include jcb

@mixin fit-image
  width: 100%
  height: 100%
  object-fit: cover

@mixin risk-count
  @include jcc
  @include aic
  height: 30px
  width: 30px

@mixin disabled-button
  cursor: default
  pointer-events: none
  color: $gray-disabled

@mixin accordion-item
  margin-bottom: $indent*2
  padding-bottom: $indent*2
  border-bottom: $border

@mixin mover-animation
  animation: mover .2s
  -webkit-animation: mover .2s

@mixin custom-error
  display: block
  font-size: 11px
  letter-spacing: 0.0333333333em // same as in --mat-form-field-subscript-text-tracking
  line-height: normal
  color: $error

@mixin inverted-logo
  filter: invert(.75)
  -webkit-filter: invert(75%)

@mixin accordion-title
  font-size: 16px
  font-weight: 600
  align-self: center
