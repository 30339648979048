$gray-label: rgba(0, 0, 0, 0.54)!important
$gray-disabled: rgba(0, 0, 0, 0.87)!important
$nav-tabs-height: 48px

// Ionicons styles rewrite fix
// TODO uncomment after ContentBuilder update and check material input component styles
.mdc-notched-outline__notch
  border-left: unset !important
  border-right: unset !important

.mat-button-toggle
  border-left: none!important

.mat-mdc-select-value, .mdc-text-field__input
  color: $gray-disabled

.mdc-button .mdc-button__label, .mdc-tab__text-label
  letter-spacing: normal

.mat-input-element:disabled
  color: $gray-disabled

.mat-select-disabled
  .mat-select-value
    color: $gray-disabled

mat-icon
  &.button
    cursor: pointer

  &.disabled
    cursor: default
    pointer-events: none
    color: $gray-label

mat-form-field
  mat-label
    color: $gray-label

  textarea
    min-height: 100px

  .mat-mdc-form-field-infix
    width: auto

.mat-mdc-snack-bar-container
  &.error
    .mdc-snackbar__surface
      background-color: #980c0c
      color: #fff

.mat-tooltip
  font-size: .8em

.mat-accordion
  .mat-expansion-panel
    border-radius: 0!important

.mat-mdc-tab-nav-bar
  min-height: $nav-tabs-height

mat-error, mat-hint
  font-size: 11px
  margin-top: -4px

.mat-mdc-select-panel, .mat-mdc-autocomplete-panel
  .mat-mdc-option
    .mat-pseudo-checkbox-minimal
      display: none
